<script>
import coachs from "@/coachs.json";
import { required } from "vuelidate/lib/validators";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import axios from "axios";

export default {
  props: ["user"],
  components: {},
  filters: {
    truncate: function (data, num) {
      const reqdString = data.split("").slice(0, num).join("");
      return reqdString;
    },
  },
  data() {
    return {
      ticket: null,
      messagesTicket: [],
      tickets: [],
      currentCoach: null,
      index: 0,
      lastVisible: null,
      endLoadMore: false,
      loadMoreSpinner: false,
      coachs: coachs,
      isUpdatingStatus: false,
      isSendingReply: false,
      saving_modification_ticket_coach: false,
      optionsCoach: [{ value: null, text: "NO" }],
      title: "Chat",
      state: true,
      options: [
        { value: "any", text: "All" },
        { value: true, text: "Open" },
        { value: false, text: "Closed" },
      ],
      items: [
        {
          text: "Apps",
        },
        {
          text: "Chat",
          active: true,
        },
      ],
      submitted: false,
      form: {
        message: "",
      },
      username: "Designers",
      member: null,
    };
  },
  validations: {
    form: {
      message: {
        required,
      },
    },
  },
  mounted() {
    this.currentCoach = firebase.auth().currentUser;
    this.loadTickets();
    this.getOptionsCoach();
  },
  methods: {

    async saveModificationTicketCoach(){

      this.saving_modification_ticket_coach = true;
      console.log(this.ticket.id);
      console.log(document.getElementById('selectedCoach').value);

      var coachId = document.getElementById('selectedCoach').value;

      var referencecoach = firebase
        .firestore()
        .collection("users")
        .doc(coachId);

      await firebase
        .firestore()
        .collection("premiumtickets")
        .doc(this.ticket.id)
        .update({ coach: referencecoach });

      this.saving_modification_ticket_coach = false;

      
    },
    async getOptionsCoach() {
      var db = firebase.firestore();
      await db
        .collection("users")
        .where("role", "array-contains", "coach")
        .get()
        .then((coachs) => {
          coachs.forEach((coach) => {
            this.optionsCoach.push({
              value: coach.id,
              text: coach.data().first_name + " " + coach.data().last_name,
            });
          });
        });
      console.log("coach: " + JSON.stringify(this.optionsCoach));
    },
    /**
     * Get the name of user
     */
    waitingReply() {
      var isWaitingReply = false;
      if(this.messagesTicket == null) return false;
      this.messagesTicket.forEach((message) => {
        if (!message.data.status) isWaitingReply = true;
      });
      return isWaitingReply;
    },
    async deleteTicket(ticket) {
      await firebase
        .firestore()
        .collection("premiumtickets")
        .doc(ticket.id)
        .delete();

      this.reloadTickets();
    },
    async deleteMessage(ticket, message) {
      await firebase
        .firestore()
        .collection("premiumtickets")
        .doc(ticket.id)
        .collection("messages")
        .doc(message.id)
        .delete();

      this.setTicket(ticket);
    },
    async publishReply(index, message, ticket) {
      //On affiche le spinner
      document.getElementById("spinner-publish-button-" + index).style.display =
        "inline-block";

      await firebase
        .firestore()
        .collection("premiumtickets")
        .doc(ticket.id)
        .collection("messages")
        .doc(message.id)
        .update({ status: true });

      //On envoi une notification si c'est un coach admin qui envoi
        var notification =
          '{"alert":{"title":"Nuovo messaggio privato","text":"' +
          message.data.message +
          '"}}';

        const requestOptions = {
          method: "POST",
        };

        fetch(
          "https://management-api.wonderpush.com/v1/deliveries?accessToken=ZTg4ZGRjMjMyYWE2Mzg1MDUyOTA0ZmVkYTlmNmU1YWE0ZWIyYjVmYjA5NmNhNWNhZDJiMTMyZDcyYzU0ODFmMw&targetUserIds=" +
            ticket.data.user.id +
            "&notification=" +
            notification,
          requestOptions
        );
      

      this.setTicket(ticket);
    },
    async saveModifReply(index, ticket, message) {
      //On affiche le spinner
      document.getElementById("spinner-save-button-" + index).style.display =
        "inline-block";

      var newmessage = document.getElementById(
        "content-reply-" + index
      ).innerHTML;
      console.log("message: " + message);
      await firebase
        .firestore()
        .collection("premiumtickets")
        .doc(ticket.id)
        .collection("messages")
        .doc(message.id)
        .update({ message: newmessage });

      document.getElementById("spinner-save-button-" + index).style.display =
        "none";

      this.hideButtonModif(index);
    },
    async addReplyTicket(ticket, close) {
      var message = document.getElementById("reply-message").value;

      this.isSendingReply = true;

      var coachId = firebase.auth().currentUser.uid;
      var referencecoach = firebase
        .firestore()
        .collection("users")
        .doc(coachId);

      var newmessage = {
        date: new Date().getTime(),
        status: JSON.parse(this.isAdmin) ? true : false,
        message: message,
        photos: [],
        author: referencecoach,
      };

      await firebase
        .firestore()
        .collection("premiumtickets")
        .doc(ticket.id)
        .collection("messages")
        .add(newmessage);

      firebase.firestore().collection("premiumtickets").doc(ticket.id).update({
        date_last_message: new Date().getTime(),
        last_user_ticket: referencecoach,
      });

      //On envoi un push au user
      //On envoi une notification si c'est un coach admin qui envoi
      if (JSON.parse(this.isAdmin)) {
        var notification =
          '{"alert":{"title":"Nuovo messaggio privato","text":"' +
          message +
          '"}}';

        const requestOptions = {
          method: "POST",
        };

        fetch(
          "https://management-api.wonderpush.com/v1/deliveries?accessToken=ZTg4ZGRjMjMyYWE2Mzg1MDUyOTA0ZmVkYTlmNmU1YWE0ZWIyYjVmYjA5NmNhNWNhZDJiMTMyZDcyYzU0ODFmMw&targetUserIds=" +
            ticket.userId +
            "&notification=" +
            notification,
          requestOptions
        );
      }

      if (close) {
        await firebase
          .firestore()
          .collection("premiumtickets")
          .doc(ticket.id)
          .update({ status: false });
        this.ticket.data.status = false;
      }

      document.getElementById("reply-message").value = "";

      this.setTicket(ticket);
      this.scrollBottom();

      this.isSendingReply = false;
    },
    showButtonModif(index) {
      //On affiche le bouton modifier
      document.getElementById("button-save-" + index).style.display = "block";
      //On cache le bouton de moderation si il existe
      if (document.getElementById("button-publish-" + index))
        document.getElementById("button-publish-" + index).style.display =
          "none";
    },
    hideButtonModif(index) {
      //On affiche le bouton modifier
      document.getElementById("button-save-" + index).style.display = "none";
      //On cache le bouton de moderation si il existe
      if (document.getElementById("button-publish-" + index))
        document.getElementById("button-publish-" + index).style.display =
          "block";
    },
    async updateTicketStatus(ticket, status) {
      this.isUpdatingStatus = true;
      await firebase
        .firestore()
        .collection("premiumtickets")
        .doc(ticket.id)
        .update({ status: status });
      ticket.data.status = status;
      this.isUpdatingStatus = false;
    },
    reloadTickets() {
      this.tickets = [];
      this.lastVisible = null;
      this.ticket = null;
      this.messagesTicket = [];
      this.endLoadMore = false;
      this.loadTickets();
    },
    loadTickets() {
      this.loadMoreSpinner = true;
      var requete = firebase
        .firestore()
        .collection("premiumtickets")
        .orderBy("date_last_message", "desc");

      if (this.lastVisible != null) {
        requete = requete.startAfter(this.lastVisible);
      }

      if (this.user == null && this.state != "any") {
        console.log("state: " + this.state);
        requete = requete.where("status", "==", this.state);
      }

      if (this.user != null) {
        console.log("user");
        var referenceuser = firebase
          .firestore()
          .collection("users")
          .doc(this.user.toString());
        requete = requete.where("user", "==", referenceuser);
      }

      if (!JSON.parse(this.isAdmin) && this.user == null) {
        console.log("check coach");
        var coachId = firebase.auth().currentUser.uid;
        var referencecoach = firebase
          .firestore()
          .collection("users")
          .doc(coachId);
        requete = requete.where("coach", "==", referencecoach);
      }

      requete
        .limit(300)
        .get()
        .then((tickets) => {
          console.log(JSON.stringify(tickets));
          this.lastVisible = tickets.docs[tickets.docs.length - 1];
          if (tickets.docs.length == 0) {
            console.log("length: " + tickets.docs.length);
            this.endLoadMore = true;
          }
          tickets.forEach(async (ticket) => {
            var data = ticket.data();
            var user = await data.user.get();
            var userId = await data.user.id;
            var userName = user.data().first_name + " " + user.data().last_name;
            var coach = await data.coach.get();
            var coachName =
              coach.data()["first_name"] + " " + coach.data()["last_name"];

            var messages = await firebase
              .firestore()
              .collection("premiumtickets")
              .doc(ticket.id)
              .collection("messages")
              .orderBy("date", "asc")
              .get();

            var lastMessageData = messages.docs[messages.docs.length-1];

           

            this.tickets.push({
              id: ticket.id,
              data: ticket.data(),
              user: userName,
              userId: userId,
              coach: coachName,
              lastMessage: lastMessageData ? lastMessageData.data() : null
            });
            
            this.loadMoreSpinner = false;
          });
          //document.getElementById("savemodifications").style.display = "none";
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    },
    async setTicket(ticket) {
      this.messagesTicket = [];
      this.hideAllSaveButton();
      this.showAllPublishButton();

      this.ticket = ticket;
      var messages = await firebase
        .firestore()
        .collection("premiumtickets")
        .doc(this.ticket.id)
        .collection("messages")
        .orderBy("date", "asc")
        .get();

      console.log("nb messages: " + messages.docs.length);

      if(messages.docs.length == 0) this.messagesTicket = null;
      for (var i = 0; i < messages.docs.length; i++) {
        var data = messages.docs[i].data();
        await data.author.get().then((author) => {
          var authorName =
            author.data().first_name + " " + author.data().last_name;

          this.messagesTicket.push({
            id: messages.docs[i].id,
            data: messages.docs[i].data(),
            authorName: authorName,
          });
        });
      }
    },
    scrollBottom() {
      console.log("on appelle scroll");
      var element = document.getElementById("conversation-ticket");
      element.scrollTop = element.scrollHeight;
    },
    hideAllSaveButton() {
      var elements = document.querySelectorAll('[id ^= "button-save-"]');
      elements.forEach((element) => {
        element.style.display = "none";
      });
    },
    showAllPublishButton() {
      var elements = document.querySelectorAll('[id ^= "button-publish-"]');
      elements.forEach((element) => {
        element.style.display = "block";
      });
    },
    dateFromTimestamp(timestamp) {
      var date = new Date(timestamp);
      return (
        date.getDate() +
        "-" +
        (date.getMonth() + 1) +
        "-" +
        date.getFullYear() +
        " " +
        date.getHours() +
        ":" +
        date.getMinutes()
      );
    },
    retrieveOldMessage(ticket){

      axios({
        method: "post", //put
        url:
          "https://gabriellavico.coach/wp-json/customapi/v1/getdiscussionsmigrationold?iduser=" +
          ticket.userId + "&timestamp=" + ticket.data.date_start,
      }).then((response) => {
        var discussion = JSON.parse(response.data);
        console.log(discussion);

        var db = firebase.firestore();

        discussion.forEach((message) => {
              var referenceauthor = db
                .collection("users")
                .doc(message.author.toString());

              message.author = referenceauthor;

              db.collection("premiumtickets")
                .doc(ticket.id)
                .collection("messages")
                .add(message);

              console.log("message added");
            });

        this.setTicket(ticket);

      });

    }
  
  },
};
</script>

<style>
.emoji {
  max-width: 20px !important;
}

.ctext-wrap-content {
  max-width: 70% !important;
  min-width: 300px;
  /*min-width: 70% !important;*/
}

.chat-conversation .conversation-list {
  width: 100% !important;
}

.div-in-moderation {
  padding: 5px;
  background-color: #ffd7d4;
  margin-top: 5px;
}

.list-tickets {
  height: 500px;
  overflow-y: scroll;
}

.conversation-ticket {
  height: 450px;
  padding-bottom: 50px;
  overflow-y: scroll;
}
</style>

<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-4 card" style="height: 650px">
        <div class="pb-3">
          <div class="py-2">
            <b-form-select
              v-model="state"
              :options="options"
              @change="reloadTickets()"
              v-if="user == null"
              style="width: 100%"
            ></b-form-select>
          </div>

          <div class="alert alert-success" role="alert">
            {{ tickets != null ? tickets.length : "..." }} tickets
            <i
              class="fas fa-sync-alt m-1"
              style="float: right; cursor: pointer"
              @click="reloadTickets()"
            ></i>
          </div>

          <div class="p-2 list-tickets">
            <ul v-if="tickets != null" class="list-unstyled chat-list">
              <li v-for="ticket in tickets" :key="'list-ticket-' + ticket.id">
                <a @click="setTicket(ticket)">
                  <div class="media">
                    <div class="media-body overflow-hidden">
                      <h5 class="text-truncate font-size-14">
                        <i
                          class="mdi mdi-circle align-middle font-size-10 ms-1"
                          :class="
                            ticket.data.status ? 'text-success' : 'text-danger'
                          "
                        ></i>
                        {{ ticket.user }}
                        <br /><span class="text-secondary"
                          ><small> @{{ ticket.coach }}</small></span
                        >
                        
                      </h5>
                      <p class="text-danger" v-if="ticket.lastMessage && ticket.lastMessage.author.id == ticket.userId">
                          The Coach must answer
                        </p>
                        <p class="text-warning" role="alert" v-if="ticket.lastMessage && !ticket.lastMessage.status">
                          Waiting for moderation...
                        </p>
                    </div>
                    <div class="font-size-11">
                      {{ dateFromTimestamp(ticket.data.date_start) }}<br/>
                      <b>last:{{ dateFromTimestamp(ticket.data.date_last_message) }}</b>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
            <div class="d-grid gap-2 mt-2">
              <button
                type="button"
                class="btn btn-success btn-block"
                @click="loadTickets()"
                v-if="tickets != null && endLoadMore != true"
              >
                <span
                  v-if="loadMoreSpinner"
                  class="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Load More
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-8" v-if="ticket == null">
        <div class="card" style="height: 650px">
          <div class="position-absolute top-50 start-50">select a ticket</div>
        </div>
      </div>

      <div class="col-8" v-if="ticket != null">
        <div class="card" style="height: 650px">
          <div class="p-3 px-lg-4 border-bottom">
            <div class="row">
              <div class="col-md-4 col-6">
                <h5 class="font-size-16 mb-1 text-truncate">
                  Chat with
                  <router-link
                    :to="{ name: 'Profile', params: { id: ticket.userId } }"
                  >
                    {{ ticket.user }}</router-link
                  >
                </h5>
                 
                <p class="text-muted text-truncate mb-0" v-if="messagesTicket != null">
                  <i class="uil uil-users-alt me-1"></i>
                  {{ messagesTicket.length }}
                  messages
                </p>

                <p class="text-muted" v-if="messagesTicket != null">
                  {{ ticket.id }}
                </p>
              </div>
              <div class="col-md-4 col-6" v-if="JSON.parse(isAdmin)">
                <div class="d-flex flex-row">
                <b-form-select
                  id="selectedCoach"
                  v-model="ticket.coach.id"
                  :options="optionsCoach"
                  size="sm"
                  style="width: 100%; margin-right: 5px;"
                ></b-form-select>
                <button
                  class="btn btn-success btn-sm ml-1"
                  @click="saveModificationTicketCoach()"
                  type="button"
                >
                  <span
                    v-if="saving_modification_ticket_coach"
                    class="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Change
                </button>
                </div>
              </div>
              <div class="col-md-4 col-6">
                <div class="d-flex flex-row-reverse">
                  <button
                    v-if="waitingReply()"
                    type="button"
                    class="btn float-right"
                    disabled
                  >
                    Message in moderation...
                  </button>

                  <button
                    v-if="!waitingReply() && JSON.parse(isAdmin)"
                    type="button"
                    class="btn float-right btn-danger mx-3"
                    @dblclick="deleteTicket(ticket)"
                  >
                    <i class="fas fa-trash-alt"></i>
                  </button>

                  <button
                    v-if="!waitingReply() && JSON.parse(isAdmin)"
                    type="button"
                    class="btn float-right"
                    :class="ticket.data.status ? 'btn-danger' : 'btn-success'"
                    @click="
                      updateTicketStatus(
                        ticket,
                        ticket.data.status ? false : true
                      )
                    "
                  >
                    <span
                      v-if="this.isUpdatingStatus"
                      class="spinner-grow spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    {{
                      ticket.data.status
                        ? "Close the ticket"
                        : "Open the ticket"
                    }}
                  </button>
                </div>
              </div>
            </div>
          </div>


          <div
            v-if="ticket != null"
            class="px-lg-2 chat-users conversation-ticket"
            id="conversation-ticket"
          >
            <div class="chat-conversation p-3">
              <ul class="list-unstyled mb-0">
                <div>
                  <div v-if="messagesTicket == null">
                    No messages!
                    
                    <button type="button" class="btn btn-primary" @click="retrieveOldMessage(ticket)">
            Recupèrer les messages
          </button>
                    
                  </div>
                  <li
                    v-for="(message, index) in messagesTicket"
                    :key="message.id"
                    :class="[
                      message.data.author.id == ticket.data.coach.id
                        ? ''
                        : 'right',
                    ]"
                  >
                    <div class="conversation-list">
                      <div
                        class="ctext-wrap"
                        :class="
                          message.data.author.id == ticket.data.coach.id
                            ? ''
                            : 'd-flex flex-row-reverse'
                        "
                      >
                        <div
                          class="ctext-wrap-content"
                          :style="
                            !message.data.status
                              ? 'background-color: #ffd7d4;'
                              : ''
                          "
                        >
                          <i
                            class="fas fa-trash-alt"
                            @dblclick="deleteMessage(ticket, message)"
                            v-if="JSON.parse(isAdmin)"
                            :class="
                              message.data.author.id == ticket.data.coach.id
                                ? 'float-end'
                                : 'float-start'
                            "
                          ></i>

                          <h5 class="font-size-14 conversation-name">
                            <a class="text-dark">
                              {{ message.authorName }}
                            </a>
                          </h5>
                          <span class="d-inline-block font-size-10 text-muted"
                            >{{ dateFromTimestamp(message.data.date) }}
                          </span>
                          <div
                            style="
                              margin-top: 10px;
                              margin-bottom: 10px;
                              display: none;
                            "
                            :id="'button-save-' + index"
                          >
                            <button
                              type="button"
                              class="btn btn-success m-10"
                              @click="saveModifReply(index, ticket, message)"
                            >
                              <span
                                :id="'spinner-save-button-' + index"
                                style="display: none"
                                class="spinner-grow spinner-grow-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              Save
                            </button>
                          </div>
                          <div
                            style="margin-top: 10px; margin-bottom: 10px"
                            v-if="!message.data.status"
                            :id="'button-publish-' + index"
                          >
                            <button
                              type="button"
                              class="btn btn-warning m-10"
                              @click="publishReply(index, message, ticket)"
                              v-if="JSON.parse(isAdmin)"
                            >
                              <span
                                :id="'spinner-publish-button-' + index"
                                style="display: none"
                                class="spinner-grow spinner-grow-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              Publish
                            </button>
                          </div>
                          <div
                            class="mb-0"
                            :data-index="index"
                            @keyup="showButtonModif(index)"
                            :contenteditable="
                              JSON.parse(isAdmin) ||
                              message.data.author.id == currentCoach.uid
                                ? true
                                : false
                            "
                            style="outline: none"
                            v-html="
                              message.data.message.replace(
                                /(?:\r\n|\r|\n)/g,
                                '<br />'
                              )
                            "
                            :id="'content-reply-' + index"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </li>
                </div>
              </ul>
            </div>
          </div>

          <div class="p-3 chat-input-section">
            <div v-if="!ticket.data.status">
              <div class="alert alert-danger" role="alert">
                This ticket is close.
              </div>
            </div>

            <div class="row" v-if="ticket.data.status">
              <div class="col">
                <div class="position-relative">
                  <textarea
                    rows="3"
                    class="form-control chat-input rounded"
                    :v-bind="form.message"
                    placeholder="Enter Message..."
                    id="reply-message"
                  ></textarea>
                  <div
                    v-if="submitted && $v.form.message.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.form.message.required"
                      >This value is required.</span
                    >
                  </div>
                </div>
              </div>
              <div class="col-auto">
                <div class="mb-1">
                  <button
                    type="button"
                    class="
                      btn btn-primary
                      chat-send
                      w-md
                      waves-effect waves-light
                    "
                    @click="addReplyTicket(ticket, false)"
                  >
                    <span
                      v-if="this.isSendingReply"
                      class="spinner-grow spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span class="d-none d-sm-inline-block me-2">Send</span>
                    <i class="mdi mdi-send float-end"></i>
                  </button>
                </div>
                <div v-if="JSON.parse(isAdmin)">
                  <button
                    type="button"
                    class="
                      btn btn-primary
                      chat-send
                      w-md
                      waves-effect waves-light
                    "
                    @click="addReplyTicket(ticket, true)"
                  >
                    <span
                      v-if="this.isSendingReply"
                      class="spinner-grow spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span class="d-none d-sm-inline-block me-2"
                      >Send & Close</span
                    >
                    <i class="mdi mdi-send float-end"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>